.container{
    width: 100vw !important;
    height: 100vh;

    background-image: url('./geladeira-refrigerador-french-door-electrolux-579l-inox-electrolux-DM84X_Detalhe18.png');
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
}

.advice{
    width: 50%;
    height: 50%;
    min-width: 350px;
    min-height: 500px;
    background: ghostwhite;
    border-radius: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logopng{
    width: 300px;
    left: 0;
    top: 0;
    z-index: 25;
}

.leyenda{
    width: 80%;
    margin: 0px 10%;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    line-height: 48px;
    color: rgb(0, 0, 82);
}